import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import crud from "../../Assets/Projects/crud.jpg";
import chatbot from "../../Assets/Projects/chatbot.png";
import hebsmusic from "../../Assets/Projects/hebsmusic.png";
import hebsdelivery from "../../Assets/Projects/hebsdelivery.png";
import MetaTag from "../Helmet";

function Projects() {
  return (
    <Container fluid className="project-section">
      <MetaTag title={"Vullnet | Project"} />

      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a Three projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatbot}
              isBlog={false}
              title="AI Chatbot- Project"
              description="I created an AI chatbot with .NET, which serves companies to facilitate the management of customer questions and answers The chatbot incorporates artificial intelligence and can be easily integrated into platforms like Facebook and Instagram. (for more info, contact me!) "
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={hebsdelivery}
              isBlog={false}
              title="Website Delivery Management and API Development with C#"
              description="Here I managed a delivery website, where I developed and maintained the admin area using an API endpoint built with C#. For more information or other details, you can contact me."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={hebsmusic}
              isBlog={false}
              title="Hebs Music Application"
              description="I created a Windows Music Application for Hebs, developed with .NET. The application works both online and offline, offering the ability to add, delete music, and manage everything in an automated manner."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={crud}
              isBlog={false}
              title="CRUD Project for Contact Management with Blazor .NET"
              description="I created a project with Blazor .NET for managing contact data by implementing CRUD (Create, Read, Update, Delete) functionalities for efficient and interactive information management."
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
